import numeral from 'numeral';
import CURRENCY_OPTIONS from 'src/../_data/currencies.json';

// ----------------------------------------------------------------------

export function fNumber(number) {
  return numeral(number).format();
}

export function fCurrency(number, currency = 'EUR') {
  let format = number ? `${numeral(number).format('0.00')} ${currency}` : '';

  if (currency === 'EUR') {
    format = number ? `${numeral(number).format('0.00')} €` : '';
  }
  if (currency === 'SEK') {
    format = number ? `${numeral(number).format('0.00')} kr` : '';
  }
  if (currency === 'GBP') {
    format = number ? `£${numeral(number).format('0.00')}` : '';
  }
  if (currency === 'USD') {
    format = number ? `$${numeral(number).format('0.00')}` : '';
  }

  return result(format, '.00');
}

export function fBigCurrency(number, currency = 'EUR') {
  if (currency === 'EUR') {
    return `${fBigNumber(number)} €`;
  }
  if (currency === 'SEK') {
    return `${fBigNumber(number)} kr`;
  }
  if (currency === 'GBP') {
    return `£${fBigNumber(number)}`;
  }
  if (currency === 'USD') {
    return `$${fBigNumber(number)}`;
  }

  return `${fBigNumber(number)} ${CURRENCY_OPTIONS[currency]?.symbol_native || currency}`;
}

export function fCurrencyUnit(currency) {
  if (currency === 'EUR') {
    return '€';
  }
  if (currency === 'SEK') {
    return 'kr';
  }
  if (currency === 'GBP') {
    return '£';
  }
  if (currency === 'USD') {
    return '$';
  }
  return CURRENCY_OPTIONS[currency]?.symbol_native || currency;
}

export function fPercent(number) {
  const format = number ? numeral(Number(number) / 100).format('0.0%') : '';

  return result(format, '.0');
}

export function fBigNumber(number) {
  if (number >= 10000000) {
    return `${numeral(number / 1000000).format('0,0')} M`;
  }
  if (number >= 1000000) {
    return `${numeral(number / 1000000).format('0,0.00')} M`;
  }
  if (number >= 100000) {
    return `${numeral(number / 1000).format('0,0.0')} k`;
  }
  return numeral(number).format('0,0');
}

export function fShortenNumber(number) {
  const format = number ? numeral(number).format('0.00a') : '';

  return result(format, '.00');
}

export function fData(number) {
  const format = number ? numeral(number).format('0.0 b') : '';

  return result(format, '.0');
}

function result(format, key = '.00') {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, '') : format;
}

export function fPhoneNumber(number) {
  if (!number) {
    return '';
  }
  return number.replace(/\D+/g, '').replace(/(\d{3})(\d{2})(\d{3})(\d*)/, '+$1 $2 $3 $4');
}
